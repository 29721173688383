import nav from './components/nav';
import 'materialize-css';
const feather = require('feather-icons')

feather.replace()
document.addEventListener('DOMContentLoaded', () => {
  nav();

});

const swiper1 = new Swiper('.box-items-carousel__box', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  slidesPerView: 5,
  spaceBetween: 30,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
      spaceBetween: 20
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 5,
      spaceBetween: 40
    }
  },
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});

const swiper2 = new Swiper('.box-items-videos__box', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  slidesPerView: 3,
  spaceBetween: 23,
  centeredSlides: false,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 23
    },
    // when window width is >= 480px
    700: {
      slidesPerView: 2,
      spaceBetween: 23,
    },
    // when window width is >= 640px
    1075: {
      slidesPerView: 3,
      spaceBetween: 23,
    }
  },

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});

const swiper3 = new Swiper('.box-thematic-carusel__box', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  slidesPerView: 5,
  spaceBetween: 23,
  centeredSlides: false,
  breakpoints: {
    // when window width is >= 320px
    300: {
      slidesPerView: 1,
      spaceBetween: 5
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 5
    },
    // when window width is >= 480px
    670: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    // when window width is >= 640px
    1030: {
      slidesPerView: 5,
      spaceBetween: 10
    }
  },

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});